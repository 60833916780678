import React, { useState, useEffect } from "react";
import styles from "./transactionStyles.module.css";

const parseAdditionalInformation = (additionalInformation) => {
  const infoArray = additionalInformation?.split(", ").map((info) => {
    return info.replace(/[\[\]']+/g, "");
  });
  const infoObject = {};

  infoArray?.forEach((info) => {
    const [key, value] = info.split(": ");
    infoObject[key] = value;
  });

  return infoObject;
};

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 950);
    };

    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return isMobile;
};

const TransactionTable = ({
  transactions,
  account,
  handleSearchTransaction,
}) => {
  console.log("transactions", transactions);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const toggleAdditionalInformation = (e, transaction) => {
    e.stopPropagation();
    setSelectedTransaction(transaction);
  };

  const closeModal = (e) => {
    e.stopPropagation();
    setSelectedTransaction(null);
  };

  const isMobile = useIsMobile();

  /*  console.log("transactions", transactions); */

  // handle the search to filter the transactions
  useEffect(() => {
    // if the search is empty, set the filtered transactions to the transactions
    if (!handleSearchTransaction) {
      setFilteredTransactions(transactions[account.iban]);
    } else {
      handleSearchTransaction = handleSearchTransaction.trim();
      // if the search is not empty, filter the transactions based on the search
      setFilteredTransactions(
        transactions[account.iban].filter((transaction) => {
          const additionalInfo = parseAdditionalInformation(
            transaction.additionalInformation
          );
          const merchant =
            transaction?.Merchant ||
            transaction?.creditorName ||
            transaction?.debtorName ||
            transaction?.proprietaryBankTransactionCode?.replace(/_/g, " ") ||
            transaction?.remittanceInformationUnstructuredArray ||
            (additionalInfo?.narrative?.split(", ")[0] ===
            "BETALING MET DEBETKAART"
              ? "BETALING MET DEBETKAART" +
                (isMobile ? "" : " " + additionalInfo.atmPosName)
              : additionalInfo?.narrative?.split(", ")[0]);

          // Check if merchant is a string before calling replace
          const merchantName =
            typeof merchant === "string"
              ? merchant.replace(/'/g, "")
              : "Unknown Merchant";

          // Extract transaction amount
          const transactionAmount =
            transaction?.transactionAmount?.amount?.toString() || "";

          return (
            merchantName
              .toLowerCase()
              .includes(handleSearchTransaction.toLowerCase()) ||
            transactionAmount.includes(handleSearchTransaction)
          );
        })
      );
    }
  }, [handleSearchTransaction, transactions, account.iban, isMobile]);

  return (
    <div className={styles.transactionContainer}>
      <table className={styles.transactionTable}>
        <thead>
          <tr>
            <th>Merchant</th>
            <th>Transaction Date</th>
            <th>Amount</th>
            <th>Currency</th>
          </tr>
        </thead>
        <tbody>
          {filteredTransactions.map((transaction, transactionIndex) => {
            const additionalInfo = parseAdditionalInformation(
              transaction.additionalInformation
            );
            const merchant =
              transaction?.Merchant ||
              transaction?.creditorName ||
              transaction?.debtorName ||
              transaction?.proprietaryBankTransactionCode?.replace(/_/g, " ") ||
              transaction?.remittanceInformationUnstructuredArray ||
              (additionalInfo?.narrative?.split(", ")[0] ===
              "BETALING MET DEBETKAART"
                ? "BETALING MET DEBETKAART" +
                  (isMobile ? "" : " " + additionalInfo.atmPosName)
                : additionalInfo?.narrative?.split(", ")[0]);

            // Check if merchant is a string before calling replace
            const merchantName =
              typeof merchant === "string"
                ? merchant.replace(/'/g, "")
                : "Unknown Merchant";

            const formattedMerchantName =
              merchantName.charAt(0).toUpperCase() +
              merchantName.slice(1).toLowerCase();

            return (
              <tr
                key={transactionIndex}
                className={styles.transactionItem}
                onClick={(e) => {
                  toggleAdditionalInformation(e, transaction);
                }}
              >
                <td>{formattedMerchantName}</td>
                <td>{transaction.bookingDate}</td>
                <td>{transaction.transactionAmount?.amount}</td>
                <td>{transaction.transactionAmount?.currency}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {filteredTransactions.length < 1 && (
        <h2 className={styles.noTransactionsFound}>No transactions found</h2>
      )}

      {selectedTransaction && (
        <div
          className={styles.modal}
          onClick={(e) => {
            e.stopPropagation();
            closeModal(e);
          }}
        >
          <div className={styles.modalContent}>
            <span
              className={styles.close}
              onClick={(e) => {
                e.stopPropagation();
                closeModal(e);
              }}
            >
              &times;
            </span>
            <h2>Additional Information</h2>
            {selectedTransaction.additionalInformation ? (
              <pre onClick={(e) => e.stopPropagation()}>
                {JSON.stringify(
                  parseAdditionalInformation(
                    selectedTransaction.additionalInformation
                  ),
                  null,
                  2
                )}
              </pre>
            ) : (
              <pre onClick={(e) => e.stopPropagation()}>
                {selectedTransaction.remittanceInformationUnstructured &&
                  `Remittance Information: ${selectedTransaction.remittanceInformationUnstructured}`}
                {selectedTransaction.proprietaryBankTransactionCode &&
                  `Proprietary Bank Transaction Code: ${selectedTransaction.proprietaryBankTransactionCode}`}
              </pre>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionTable;
